import React from "react";
import { useEffect, useState } from "react";
import { getAllDocsSnap } from "../../utils/Firebase.utils";

function FeaturedProperties() {
  const [properties, setProperties] = useState([]);
  useEffect(() => {
    const fetchProperties = async () => {
      const properties = getAllDocsSnap("listings", (data) => {
        //filter team lead
        const filteredProperties = data.filter((property) => {
          return (
            property.primaryPhotoUrl !== undefined &&
            property.primaryPhotoUrl !== null &&
            property.selling_price !== undefined &&
            property.selling_price !== null &&
            property.listing_status !== "10%"
          );
        });
        setProperties(filteredProperties);
        // console.log(filteredProperties);
      });
    };
    fetchProperties();
  }, []);

  return (
    <div className="overflow-scroll">
      <div className="text-4xl font-semibold mt-8 mb-4">
        Featured Properties
      </div>
      <div className="flex items-center gap-2 h-[400px] overflow-scroll">
        <div className="flex items-center gap-2 h-[300px]">
          {properties.map((property) => {
            return (
              <div
                className="bg-gray-100 p-4 rounded-xl shadow-md m-4 h-full w-[300px] "
                key={property.id}
              >
                <div>
                  <img
                    src={property.primaryPhotoUrl}
                    alt="property"
                    className="w-full h-[200px] object-cover rounded-md"
                  />
                </div>
                <div className="font-semibold text-xs mt-4 text-left">
                  {property.property_name}
                </div>
                <div className="text-xs text-left">
                  {parseFloat(property.selling_price).toLocaleString("en-US", {
                    style: "currency",
                    currency: "PHP",
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FeaturedProperties;
