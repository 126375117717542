import React from "react";

function PropertyBar() {
  return (
    <div className="bg-gray-100 px-4 py-2">
      <div>
        <div className="text-2xl font-semibold mb-4">
          Search for your next deal here!
        </div>
        <form className="flex flex-col lg:flex-row items-center gap-2 justify-center">
          <input
            className="px-4 py-1 rounded-xl border-2 border-gray-300"
            type="search"
            placeholder="Search Here"
          />

          <input
            type="text"
            className="px-4 py-1 rounded-xl border-2 border-gray-300"
            placeholder="Location"
          />

          <input
            className="px-4 py-1 rounded-xl border-2 border-gray-300"
            type="text"
            placeholder="Property Type"
          />
          <button className="px-4 py-1 bg-blue-800 text-white rounded-xl">
            Search
          </button>
        </form>
      </div>
    </div>
  );
}

export default PropertyBar;
