import React from "react";
import PropertyBar from "./PropertyBar/PropertyBar";
import FeaturedProperties from "./FeaturedProperties/FeaturedProperties";
import Footer from "./Footer/Footer";

function Home() {
  return (
    <div className="flex flex-col">
      <div className="bg-blue-800 text-white flex flex-col lg:flex-row items-center justify-between px-4 py-2 ">
        <div>
          <div className="text-lg font-semibold">MyRealtorPh</div>
        </div>
        <div className="flex items-center gap-2 ">
          <div className="navbar-item ">About Us</div>
          <div className="navbar-item">Sign Up</div>
          <div className="navbar-item">Pricing</div>
        </div>
      </div>
      <div
        className="bg-[url('https://images.unsplash.com/photo-1565402170291-8491f14678db?q=80&w=2017&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')]
      h-[500px] bg-cover bg-center bg-no-repeat flex items-center justify-center text-white text-4xl font-semibold
      
      "
      >
        <div className="bg-gray-800 px-4 py-2 w-full opacity-75 h-full flex flex-col items-center justify-center">
          <div className="bg-black p-8">
            <div>MyRealtorPh</div>
            <div className="text-xl">Where Good Deals Happen</div>
          </div>
        </div>
      </div>
      <div>
        <PropertyBar />
      </div>
      <div>
        <FeaturedProperties />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
