import React from "react";

function Footer() {
  return (
    <div className="h-[300px] bg-gray-800 text-white">
      <div className="h-full flex items-center justify-between px-4 py-2 grid-cols-2 ">
        <div className="text-left w-1/2">
          <div>Contact us</div>
          <div>Infinity Eight Data Solutions Inc. </div>
          <div>5th Floor Phinma Plaza, Plaza Drive, Rockwell, Makati City</div>
        </div>
        <div className="w-[500px]">
          <div>Privacy Policy</div>
          <div className="text-[8px] text-left">
            We value your privacy and strive to ensure the protection of your
            personal information. When you visit our realty website, we may
            collect certain data such as your name, email address, and phone
            number, provided voluntarily by you for the purpose of responding to
            inquiries or providing realty services. Rest assured, we do not
            share or sell your personal information to third parties without
            your consent. We employ industry-standard security measures to
            safeguard your data from unauthorized access, alteration, or
            destruction. Our website may use cookies to enhance your browsing
            experience, and you have the option to accept or decline them.
            Additionally, while our site may contain links to third-party
            websites, we are not responsible for their privacy practices. We
            encourage you to review the privacy policies of any third-party
            sites you visit. We may update our Privacy Policy periodically, and
            any changes will be posted on this page. By continuing to use our
            website, you acknowledge and agree to the terms of the updated
            Privacy Policy. If you have any questions or concerns about our
            Privacy Policy, please contact us. Thank you for entrusting us with
            your information.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
